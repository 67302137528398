export const getScreenWidth = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth

  return width
}

export const getScreenHeight = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    height = w.innerHeight || e.clientHeight || g.clientHeight

  return height
}

export const isMobile = () => {
  if (getScreenWidth() <= 1200) return true
}

export const isPhone = () => {
  if (getScreenWidth() <= 440) return true
}

export const getCoords = elem => {
  let box = elem.getBoundingClientRect()

  let body = document.body
  let docEl = document.documentElement

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  let clientTop = docEl.clientTop || body.clientTop || 0
  let clientLeft = docEl.clientLeft || body.clientLeft || 0

  let top = box.top + scrollTop - clientTop
  let left = box.left + scrollLeft - clientLeft

  return {
    top: Math.round(top),
    left: Math.round(left)
  }
}

export async function callAjax(options = {}, url = '') {
  const _url = url || jsData.ajaxUrl

  return await fetch(_url, options)
}

export const overlay = (callback, appendContainer = null) => {
  appendContainer = appendContainer || document.body

  const overlay = document.createElement('div')
  overlay.classList.add('overlay')
  appendContainer.appendChild(overlay)

  overlay.classList.add('active')
  overlay.addEventListener('click', () => {
    overlay.classList.remove('active')
    callback()
  })
}

export const createLoader = (container = document.body) => {
  if (container) {
    const loader = document.createElement('div')

    loader.classList.add('loader')
    container == document.body && loader.classList.add('fixed')

    if (window.getComputedStyle(container).position == 'static') container.style.position = 'relative'

    return container.appendChild(loader)
  }
}
