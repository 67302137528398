import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import HeroSlider from './HeroSlider'

export const heroSlider = () => new HeroSlider()

export const logosSlider = () => {
  new Swiper('.logos-slider .swiper-container', {
    loop: true,
    speed: 1500,
    slidesPerView: 2,
    autoplay: {
      delay: 700,
      disableOnInteraction: false
    },
    breakpoints: {
      1: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      800: {
        slidesPerView: 6,
        spaceBetween: 40
      }
    }
  })
}

export const testimonialsSlider = () => {
  new Swiper('.testimonials-slider .swiper-container', {
    loop: true,
    speed: 1500,
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  })
}

export const tilesSlider = () => {
  const slider = document.querySelector('.tiles-slider-container .swiper-container')
  if (slider) {
    const slidesCount = slider.querySelectorAll('.swiper-slide').length
    new Swiper(slider, {
      speed: 1500,
      slidesPerView: 1,
      spaceBetween: 8,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        reverseDirection: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        760: {
          slidesPerView: 2,
          loop: slidesCount > 2 ? true : false
        }
      }
    })
  }
}
