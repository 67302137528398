import CoreAnimation from './../animations/CoreAnimations'
import { isMobile } from './../helper'

interface IOptions {
  selectedIndex: number
  activeClass: string
  mobileCollapseSpeed: number
}

export default class {
  container: HTMLElement
  options: IOptions
  tabs: NodeListOf<HTMLElement>
  contents: NodeListOf<HTMLElement>

  constructor(container: HTMLElement, options: IOptions) {
    this.container = container
    this.options = { ...{ selectedIndex: 0, activeClass: 'is-active', mobileCollapseSpeed: 500 }, ...options }
    if (this.container) {
      this.run()
    }
  }

  private run() {
    this.tabs = this.container.querySelectorAll('[data-tab]')
    this.contents = this.container.querySelectorAll('[data-content]')

    const { selectedIndex, activeClass } = this.options
    if (selectedIndex >= 0) this.setActive(this.options.selectedIndex)

    this.tabs.forEach(tab =>
      tab.addEventListener('click', () => {
        this.removeActives()

        const id = tab.getAttribute('data-tab')
        const c = [...this.contents]
        c.find(content => {
          if (content.getAttribute('data-content') === id) {
            content.classList.add(activeClass)
            const { mobileCollapseSpeed } = this.options
            isMobile() && setTimeout(() => CoreAnimation.slideDown(content, mobileCollapseSpeed), mobileCollapseSpeed)
          }
        })
        tab.classList.add(activeClass)
      })
    )
  }

  public setActive(index: number) {
    if (this.container) {
      const { activeClass } = this.options
      index < this.tabs.length && this.tabs[index].classList.add(activeClass)
      index < this.contents.length && this.contents[index].classList.add(activeClass)
    }
  }

  private removeActives() {
    const { activeClass } = this.options
    this.tabs.forEach(content => content.classList.remove(activeClass))
    this.contents.forEach(content => {
      content.classList.remove(activeClass)
      const { mobileCollapseSpeed } = this.options
      isMobile() && CoreAnimation.slideUp(content, mobileCollapseSpeed)
    })
  }
}
