import SvgAnimation from './SvgAnimation'
import anime from 'animejs'

export default () => {
  const animation = new SvgAnimation('intro-background--single').animation(() => {
    const paths = animation.svg.querySelectorAll('path')
    const container = animation.svg.closest('.hero-banner__lines')

    return anime({
      targets: paths,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 1500,
      loop: false,
      autoplay: true,
      begin: () => {
        container.classList.add('is-active')
      }
    })
  })
}
