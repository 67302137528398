import Swiper from 'swiper'
import anime from 'animejs'

export default class {
  constructor() {
    this.slider = document.querySelector('#hero-slider .swiper-container')
    if (this.slider) {
      this.sliderInit()
    }
  }

  sliderInit() {
    new Swiper(this.slider, {
      loop: true,
      speed: 1500,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })
      .on('init', this.startDrawLines())
      .on('slideChangeTransitionStart', () => this.startDrawLines())
  }

  /**
   *
   * @param {HTMLElement} lines
   */
  drawLines(lines) {
    if (lines.length) {
      return anime({
        targets: lines,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: 3500,
        loop: false,
        autoplay: false,
        begin: () => {
          lines[0].closest('.hero-slider__lines').classList.add('active')
          lines.forEach(el => el.classList.add('is-animate'))
        }
      })
    }

    return false
  }

  startDrawLines() {
    const activeSlide = this.slider.querySelector('.swiper-slide-active')
    const lines = activeSlide.querySelectorAll('.hero-slider__lines path')
    const animeLines = this.drawLines(lines)
    if (animeLines) {
      animeLines.play()
    }
  }
}
