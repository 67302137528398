import gsap from 'gsap'

export default () => {
  document.addEventListener('svg.loaded', ({ detail }: CustomEvent) => {
    if (detail == 'country-map-image') {
      const container = document.querySelector('.countries-map')
      const items = container.querySelectorAll('#single-country')

      new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.isIntersecting && !container.classList.contains('active')) {
            gsap
              .timeline()
              .set(items, {
                autoAlpha: 0
              })
              .to(items, {
                autoAlpha: 1,
                duration: 3,
                stagger: 0.5
              })

            container.classList.add('active')
          }
        }
      }).observe(container)
    }
  })
}
