import gsap from 'gsap'

export default () => {
  document.addEventListener('svg.loaded', ({ detail }: CustomEvent) => {
    if (detail == 'professions-map') {
      const section = document.querySelector('.professions-map-container')
      const container = document.querySelector('.profession-elements')
      const svg = container.firstElementChild
      const items = svg.querySelectorAll('#single-item')

      new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.isIntersecting && !section.classList.contains('active')) {
            gsap
              .timeline()
              .set(items, {
                autoAlpha: 0
              })
              .to(items, {
                autoAlpha: 1,
                duration: 4,
                stagger: 1
              })
            section.classList.add('active')
          }
        }
      }).observe(container)
    }
  })
}
