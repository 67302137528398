export default (menuItemSelector: string) => {
  const menuItem = document.getElementById('site-navigation').querySelector(menuItemSelector)

  if (menuItem) {
    menuItem.classList.remove('current-menu-item')

    menuItem
      .closest('ul')
      .closest('li')
      .classList.remove('current-menu-ancestor', 'current-menu-parent', 'current_page_parent', 'current_page_ancestor')
  }
}
