import AOS from 'aos'
// import 'aos/dist/aos.css'

export default class {
  constructor() {
    AOS.init({
      disable: 'mobile',
      once: true,
      duration: 1000,
      mirror: true
    })
  }

  animation(elements: string, animation: string) {
    const elems = document.querySelectorAll(elements)

    if (elems.length) {
      elems.forEach(elem => {
        elem.setAttribute('data-aos', animation)
        AOS.refreshHard()
      })
    }

    return this
  }

  blockMedia() {
    const container = document.querySelectorAll('.wp-block-media-text')

    if (container.length) {
      container.forEach(cont => {
        const media = cont.querySelector('.wp-block-media-text__media')
        const text = cont.querySelector('.wp-block-media-text__content')

        if (cont.classList.contains('has-media-on-the-right')) {
          media.setAttribute('data-aos', 'fade-left')
          text.setAttribute('data-aos', 'fade-tight')
        } else {
          media.setAttribute('data-aos', 'fade-right')
          text.setAttribute('data-aos', 'fade-left')
        }

        AOS.refreshHard()
      })
    }

    return this
  }

  columns() {
    const containers = document.querySelectorAll('.wp-block-columns')

    if (containers.length) {
      containers.forEach(container => {
        const columns = container.children

        if (columns.length == 2) {
          if (!columns[0].closest('.toggle-posts-section__posts')) {
            columns[0].setAttribute('data-aos', 'fade-right')
            columns[1].setAttribute('data-aos', 'fade-left')
          }
        }

        AOS.refreshHard()
      })
    }
  }
}
