export default class {
  svgId: string
  svg: HTMLElement

  constructor(svgId: string) {
    this.svgId = svgId
  }

  animation(func: Function) {
    document.addEventListener('svg.loaded', ({ detail }: CustomEvent) => {
      if (detail == this.svgId) {
        this.svg = document.getElementById(this.svgId)
        func()
      }
    })

    return this
  }
}
