import SvgAnimation from './SvgAnimation'
import gsap from 'gsap'

export default () => {
  const animation = new SvgAnimation('people-swing-balls').animation(() => {
    const container = animation.svg

    new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.isIntersecting && !container.classList.contains('is-active')) {
          const characters = container.querySelectorAll('#illustration-character')
          const circles = container.querySelectorAll('#circle')
          const man = characters[0]
          const woman = characters[1]

          gsap.timeline({ repeat: -1, yoyo: true }).to(man, {
            rotation: '+=10',
            y: '+=10',
            x: '+=12',
            duration: 4,
            yoyoEase: true
          })

          gsap.timeline({ repeat: -1, yoyo: true }).to(woman, {
            rotation: '-=10',
            x: '+=6',
            duration: 4,
            yoyoEase: true
          })

          circles.forEach((circle, key) => {
            gsap.timeline({ repeat: -1 }).to(circle, {
              rotate: key == 0 ? 360 : -360,
              duration: 4,
              transformOrigin: 'center center'
            })
          })
        }
      }
    }).observe(container)
  })
}
