import Swiper from 'swiper'
import CoreAnimation from '../animations/CoreAnimations'

type ICategoryType = 'main' | 'sub'

export default class {
  container: HTMLElement
  navItems: NodeListOf<HTMLElement>
  mainCats: NodeListOf<HTMLElement>
  subMenus: NodeListOf<HTMLElement>
  sliders: Swiper[]
  mainCatActive: number

  constructor() {
    this.container = document.querySelector('.toggle-posts-section')
    if (this.container) this.run()
  }

  private run() {
    this.sliders = []
    this.navItems = this.container.querySelectorAll('.toggle-posts-section-nav__item')
    this.mainCats = this.container.querySelectorAll('.toggle-posts-section-nav--main-cats a')
    this.subMenus = this.container.querySelectorAll('.toggle-posts-section-nav--sub-cats')
    this.createSliders()
    const catFromGet = [...this.mainCats].findIndex(cat => window.location.hash && cat.getAttribute('id') == window.location.hash.replace('#', ''))
    this.setActive(catFromGet > -1 ? catFromGet : 0, 'main')
    this.setActive(0, 'sub')
    this.handleNav(this.navItems)
  }

  private setActive(index: number, catType: ICategoryType) {
    if (catType == 'main') {
      this.mainCatActive = index
      this.mainCats.forEach(mainCat => mainCat.classList.remove('is-active'))
      this.mainCats[index].classList.add('is-active')
      this.setActive(0, 'sub')
      this.toggleSubMenus(index)
      this.toggleSliders(index)
    }

    if (catType == 'sub') {
      const mainSlug = this.mainCats[this.mainCatActive].getAttribute('data-slug')
      const currentSubMenu = [...this.subMenus].find(subMenu => subMenu.getAttribute('data-main-cat') == mainSlug)
      const currentItem = <HTMLElement>currentSubMenu.querySelector(`.toggle-posts-section-nav__item:nth-child(${index + 1})`)
      const children = [...currentSubMenu.children]
      children.forEach(subMenu => subMenu.classList.remove('is-active'))
      currentItem.classList.add('is-active')
    }
  }

  private handleNav(items: NodeListOf<HTMLElement>) {
    items.forEach(item => {
      item.addEventListener('click', () => {
        const type = <ICategoryType>item.getAttribute('data-type')
        const index = parseInt(item.getAttribute('data-index'))
        this.setActive(index, type)
        if (type == 'main') this.sliders[this.mainCatActive].slideTo(0)
        else this.sliders[this.mainCatActive].slideTo(index)
      })
    })
  }

  private createSliders() {
    this.mainCats.forEach((mainCat, key) => {
      const slider = new Swiper(`.toggle-posts-section__posts:nth-child(${key + 1})`, {
        // loop: true,
        speed: 2500,
        slidesPerView: 1,
        spaceBetween: 100,
        initialSlide: 0,
        autoHeight: true,
        autoplay: false,
        allowTouchMove: true
      })
      this.sliders.push(slider)
      // this.sliders[key].autoplay.stop()
    })
  }

  private toggleSubMenus(index: number) {
    const item = this.mainCats[index]
    const slug = item.getAttribute('data-slug')

    const currentSubMenu = <HTMLElement>this.container.querySelector(`[data-main-cat=${slug}]`)
    const otherMenus = this.container.querySelectorAll(`[data-main-cat]:not([data-main-cat=${slug}])`)
    otherMenus.forEach(menu => CoreAnimation.slideUp(<HTMLElement>menu))
    currentSubMenu.querySelector('a').classList.add('is-active')
    CoreAnimation.slideDown(currentSubMenu)
  }

  private toggleSliders(index: number) {
    const slider = this.sliders[index]

    //disable
    this.sliders.forEach((slider, key) => {
      if (index !== key) {
        // slider.autoplay.stop()
        CoreAnimation.slideUp(slider.el)
      }
    })

    //active
    // slider.slideTo(1)
    slider.on('transitionEnd', () => {
      // const index = parseInt(slider.el.querySelector('.swiper-slide-active').getAttribute('data-swiper-slide-index'))
      const activeSlide = slider.el.querySelector('.swiper-slide-active')
      const index = Array.from(activeSlide.closest('div').parentNode.children).indexOf(activeSlide)

      const fancybox = <HTMLElement>activeSlide.querySelector('a.fancybox-youtube')
      if (fancybox) fancybox.click()

      const currentVideo = <HTMLVideoElement>activeSlide.querySelector('video')
      const videos = slider.el.querySelectorAll('video')
      if (videos.length) videos.forEach((video: HTMLVideoElement) => video.load())
      if (currentVideo) currentVideo.play()

      this.setActive(index, 'sub')
    })
    CoreAnimation.slideDown(slider.el)
    // slider.update()
    // slider.autoplay.start()
  }
}
