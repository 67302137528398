export default () => {
  const images = document.querySelectorAll('.mockup-animation__mockups-single')

  if (images.length) {
    images[0].classList.add('is-active')

    setInterval(() => {
      let activeImage = [...images].find(image => image.classList.contains('is-active'))
      images.forEach(image => image.classList.remove('is-active'))
      activeImage = activeImage && activeImage.nextElementSibling ? activeImage.nextElementSibling : images[0]
      activeImage.classList.add('is-active')
    }, 2500)
  }
}
