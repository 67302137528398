/**
 * @link https://www.npmjs.com/package/smooth-scroll
 */

import SmoothScroll from 'smooth-scroll'

const smoothScroll = new SmoothScroll('a[href*="#"]', {
  ignore: '[data-vc-container]',
  header: '#masthead',
  offset: 60
})

document.addEventListener('DOMContentLoaded', () => {
  const anchor = window.location.hash
  const elem = anchor && document.querySelector(anchor)

  if (elem) {
    setTimeout(() => window.scrollTo(0, 0), 1)
    setTimeout(() => smoothScroll.animateScroll(elem), 2000)
  }
})
