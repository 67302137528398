import Animation from './animations/Animations'
import Collapse from './modules/Collapse'
import FloatLabels from './forms/float-labels'
import MainNav from './navigation'
import MobileMenu from './MobileMenu'
import SiteHeader from './siteHeader'
import ToggleCategoriesContentSection from './modules/ToggleCategoriesContentSection'
import bannerLineAninmation from './animations/svg/bannerLineAninmation'
import circlesStagerAnimation from './animations/svg/circlesStagerAnimation'
import countriesMapAnimation from './animations/svg/countriesMapAnimation'
import peopleSwingBallsAnimation from './animations/svg/peopleSwingBallsAnimation'
import professionsMapAnimation from './animations/svg/professionsMapAnimation'
import { heroSlider, testimonialsSlider, tilesSlider, logosSlider } from './modules/sliders/sliders'
import { isMobile } from './helper'
import { setAttributeFrom } from './utils/utils'
import MockupsAnimation from './animations/MockupsAnimation'
import removeActiveMenu from './utils/removeActiveMenu'

professionsMapAnimation()
countriesMapAnimation()
bannerLineAninmation()
circlesStagerAnimation()
peopleSwingBallsAnimation()
MockupsAnimation()

window.addEventListener('load', () => {
  new SiteHeader({
    setBodyPaddingTop: false
  })
  new FloatLabels()
  const Menu = new MainNav()

  heroSlider()
  testimonialsSlider()
  tilesSlider()
  logosSlider()

  const footerContact = document.getElementById('footer-contact')
  if (footerContact) setAttributeFrom(footerContact, footerContact.closest('.wp-block-cover__inner-container'), 'id')

  new Animation()
    .animation('.wp-block-video', 'fade-up')
    .animation('h2.has-text-align-center', 'fade-up')
    .animation('.till', 'fade-up')
    .animation('.logos-slider', 'fade-up')
    .animation('.newsletter', 'fade-up')
    .animation('.testimonials-section', 'fade-left')
    .animation('.team-section .type-team', 'fade-up')
    .animation('.tiles .tile:nth-child(odd)', 'fade-right')
    .animation('.tiles .tile:nth-child(even)', 'fade-left')
    .animation('.posts-grid .hentry', 'fade-up')
    .animation('.main-posts .hentry', 'fade-up')
    .animation('.team', 'fade-up')
    .animation('.toggle-content-tabs__tab', 'fade-right')
    .animation('.toggle-content-tabs__content-container', 'fade-left')
  // .animation('.block-bg--right', 'fade-left')
  // .animation('.block-bg--left', 'fade-right')
  // .columns()

  const collapse = new Collapse(document.querySelector('.toggle-content-tabs'), { selectedIndex: 0 })
  collapse.setActive(6)

  removeActiveMenu('.page-item-3533')

  if (isMobile()) {
    Menu.closeMenu()
    Menu.addSticky()
    new MobileMenu()
  }

  new ToggleCategoriesContentSection()

  window.onresize = () => {
    if (isMobile()) {
      Menu.closeMenu()
      Menu.addSticky()
    }
  }
})
