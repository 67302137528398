import anime from 'animejs'
import SvgAnimation from './SvgAnimation'
// import gsap from 'gsap'

export default () => {
  const animation = new SvgAnimation('banner-image-circles').animation(() => {
    const container = animation.svg.closest('.hero-banner__lines')
    const circles = animation.svg.querySelectorAll('circle')

    new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.isIntersecting && !container.classList.contains('is-active')) {
          return anime({
            targets: circles,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: 3500,
            loop: false,
            autoplay: true,
            delay: anime.stagger(50),
            begin: () => {
              container.classList.add('is-active')
            }
          })
          // gsap
          //   .timeline()
          //   .set(circles, {
          //     autoAlpha: 0
          //   })
          //   .to(circles, {
          //     autoAlpha: 1,
          //     duration: 3,
          //     stagger: 0.5
          //   })
        }
      }
    }).observe(container)
  })
}
